/*************** AUTH *******************/
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

/*************** DASHBOARD *******************/
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const LOADING = "LOADING";

/************* Client Management ***************/
export const SEARCH_CLIENT = 'SEARCH_CLIENT';
export const ADD_CLIENT = 'ADD_CLIENT';
export const SAVE_ADDR = 'SAVE_ADDR';
export const SAVE_CLIENT_FORM = 'SAVE_CLIENT_FORM';
export const SAVE_ROLE = 'SAVE_ROLE';
export const SET_CLIENTPK = 'SET_CLIENTPK';

/********************* LayoutReducer ****************/
export const SET_ASIDE_COMPONENT = 'SET_ASIDE_COMPONENT';
