import {Observable, useViewModel, ViewModelBase} from "../../../_CommonModels/ViewModelBase";
import {CallServerPromise} from "../../../../utils/app/call_server";
import AppToast from "../../../../utils/misc/app_toast";

class ResetPasswordViewModel extends ViewModelBase {
  user: Observable;
  loading: boolean = false;
  constructor() {
    super();
    this.user = new Observable(this, 'user', {})
  }

  componentDidMount() {
  }

  async resetPassword(values: any, formHelpers:any) {
    console.log(values,'values')
    this.loading = true;
    this.updateView()
    const response: any = await CallServerPromise.login(values);
    try {
      if (response.success) {
        AppToast.successMsg(response.message);
        const {user} = response.data;
        this.user.setValue(user);
        this.props.history.push('/');
      } else {
        AppToast.errorMsg(response.message)
      }
    } catch (e) {
      AppToast.errorMsg(response.message)
    }
    this.loading = false;
    this.updateView()
  }
}

export default useViewModel(new ResetPasswordViewModel());
