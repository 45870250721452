import AppToast from "../misc/app_toast";
import {Observable} from "../../modules/_CommonModels/ViewModelBase";

const apiUrl = process.env.REACT_APP_API_URL;
const prefix = 'api/v2/';

const serialize = (obj: any) => {
  const str: Array<string> = [];
  Object.keys(obj).map((key: string) => {
    if (obj.hasOwnProperty(key)) {
      str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
    }
    return undefined;
  });
  return str.join("&");
};

class CallServerPromiseClass {
  appToast: AppToast;

  constructor() {
    this.appToast = new AppToast();
  }

  call_server_promise(
    api: string,
    data: any = null,
    method: string = 'post'
  ): Promise<{ success: boolean; data: any }> {
    return new Promise((resolve, eject) => {
      const controller = new AbortController();
      let responseText = '';
      const token = Observable.getReduxValue('token');
console.log("********api url="+apiUrl);
      fetch(apiUrl + prefix + api + (method === 'get' ? ('?' + serialize(data)) : ''), {
        method,
        headers: {
          ...(method === 'upload'
              ? {}
              : {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
          ),
          charset: 'utf-8',
          Authorization: `Bearer ${token}`
        },
        signal: controller.signal,
        ...(
          method === 'upload'
            ? {method: 'post', body: data}
            : method === 'post' || method === 'put'
            ? {body: JSON.stringify(data)} : {}
        )
      })
        .then(async response => {
          if (response.statusText.indexOf('Unauthorized')>-1 || response.statusText.indexOf('Unauthenticated')>-1) {
            localStorage.removeItem('persist:root2');
            window.location.href = '/login';
            // this.appToast.errorMsg('You are logged out!');
          }
          responseText = await response.text();
          return JSON.parse(responseText);
        })
        .then(response => resolve(response))
        .catch(error => {
          if (error.name === 'AbortError') {
            resolve({success: false, data: null});
          } else {
            error.responseText = responseText;
            eject(error);
          }
        });
      setTimeout(() => {
        controller.abort();
      }, 60000);
    });
  }

  //Auth
  login(credentials: any) {
    return this.call_server_promise('login', credentials);
  }

  logout() {
    return this.call_server_promise('logout', {}, 'GET');
  }

  // Profile

  getUserProfile() {
    return this.call_server_promise(`profile`, {}, 'GET');
  }

  setUserProfile(data: any) {
    return this.call_server_promise(`profile`, data, 'upload');
  }

  changePassword(password: string) {
    return this.call_server_promise(`password`, {password});
  }

  //Pricing
  getPlans() {
    return this.call_server_promise(`plans`, {}, 'GET');
  }

  generatePortalSession(data:object) {
    return this.call_server_promise(`subscribe/generate_portal_session`, data);
  }

  generateCheckoutNewUrl(data:object) {
    return this.call_server_promise(`subscribe/generate_checkout_new_url`, data);
  }

  registerFromHostedPage(data:object) {
    return this.call_server_promise(`subscribe/register_from_hosted_page`, data);
  }

  processPayment(pricing: string, amount: number) {
    return this.call_server_promise(`pricing`, {pricing, amount});
  }
  // Register new central user

  register(data: any) {
    return this.call_server_promise(`register`, data);
  }
  chargebeeRegister(data: any) {
    return this.call_server_promise(`register/chargebee`, data);
  }
  migrateTenant(data: any) {
    return this.call_server_promise(`register/migrateTenant`, data);
  }
  forgotPassword(data: any) {
    return this.call_server_promise(`forgot_password`, data);
  }

  resendVerificationEmail() {
    return this.call_server_promise(`email/resend`, {}, 'GET');
  }

  checkEmailVerification() {
    return this.call_server_promise(`email/check-verify`, {}, 'GET');
  }

  getTenants() {
    return this.call_server_promise(`tenants`, {}, 'GET');
  }

  suspendTenant(id: string) {
    return this.call_server_promise(`tenants/suspend`, {id});
  }

  restoreTenant(id: string) {
    return this.call_server_promise(`tenants/restore`, {id});
  }

  deleteTenant(id: string) {
    return this.call_server_promise(`tenants/${id}`, {}, 'DELETE');
  }
  getApiTokens() {
    return this.call_server_promise(`api_tokens`,{},'GET');
  }
  createApiToken(data:any) {
    return this.call_server_promise(`api_tokens`,data);
  }
  deleteApiToken(id:number) {
    return this.call_server_promise(`api_tokens/${id}`,{},'DELETE');
  }
  getApiTokenReferrers(data:any) {
    return this.call_server_promise(`api_token_referrers/byToken`,data);
  }
  saveApiTokenReferrer(data:any) {
    return this.call_server_promise(`api_token_referrers`,data);
  }
  deleteApiTokenReferrer(id:any) {
    return this.call_server_promise(`api_token_referrers/${id}`,{},'DELETE');
  }
  getApiTokenMaps(token_id:any) {
    return this.call_server_promise(`api_tokens/${token_id}/maps`,{},'GET');
  }
  saveApiTokenMaps(token_id:any, params:Array<string>) {
    return this.call_server_promise(`api_tokens/${token_id}/maps`, {params});
  }
}

export const CallServerPromise = new CallServerPromiseClass();
