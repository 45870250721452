import * as ActionTypes from '../action-types';

const initialState = {
  clients: [],
  view: 'Dashboard',
  BUSINESSTYPE: [],
  ADDRESSTYPE: [],
  SYSTEMROLE: [],
  Entity_Type: [],
  s_1099: [],
  AddressInfo: [],
  EmailINfo: [],
  FaxInfo: [],
  PersonInfo: [],
  PhoneInfo: [],
  RoleInfo: [],
  WebInfo: [],
  AddrFormData1: [],
  AddrFormData: [],
  is_Default_present: 'N',
  Default_Errror: 'N',
  Default_Addr_RowIndex: '',
  RoleFormData1: [],
  RoleFormData: [],
  ClientPk: "",
  PersonFormData1: [],
  isMortgagee: "N",
  isMailing: "N",
  showMortTab: false,
  policyData: [],
  MailingInfo: [],
};

const searchClient = (state, payload) => {
  const {data: clients} = payload;
  return Object.assign({}, state, {
    clients,
  });

};

const getClient = (state, payload) => {
  if (payload.MortgageeCount > 0) {
    state.isMortgagee = 'Y';
    state.showMortTab = true;
  } else {
    state.isMortgagee = "N";
    state.showMortTab = false;
  }
  if (payload.MailingInfo.length > 0) {
    state.isMailing = 'Y';
  } else {
    state.isMailing = "N";
  }
  const {BUSINESSTYPE, ADDRESSTYPE, SYSTEMROLE} = payload.dropdownData;
  const {Entity_Type} = payload;
  const {s_1099} = payload;
  const {AddressInfo: AddrFormData1} = payload;
  const {EmailInfo: EmailINfo} = payload;
  const {FaxInfo} = payload;
  const {PersonInfo} = payload;
  const {PhoneInfo} = payload;
  const {RoleInfo: RoleFormData1} = payload;
  const {WebInfo} = payload;
  const {policyData} = payload;
  return Object.assign({}, state, {
    BUSINESSTYPE,
    ADDRESSTYPE,
    SYSTEMROLE,
    Entity_Type,
    s_1099,
    AddrFormData: [],
    AddrFormData1,
    EmailINfo,
    FaxInfo,
    PersonInfo,
    PhoneInfo,
    RoleFormData1,
    WebInfo,
    ClientPk: "",
    clients: [],
    policyData,
  });

};


const saveAddr = (state, payload) => {
  const AddrFormData1 = state.AddrFormData1;
  const AddrFormData = payload;
  if (AddrFormData[0]['s_AddressTypeCode'] === "Mailing") {
    state.isMailing = "Y";
  } else {
    state.isMailing = "N";
  }
  const rowIndex = AddrFormData[0]['rowIndex'];
  if (!String(rowIndex)) {
    AddrFormData1.push(AddrFormData[0]);
  } else {
    AddrFormData1[rowIndex] = AddrFormData[0];
  }


  if (payload[0]['s_IsDefaultAddress'] === "Y") {
    //AddrFormData.push(payload);
    return Object.assign({}, state, {
      AddrFormData,
      AddrFormData1,
      is_Default_present: "Y",
      Default_Addr_RowIndex: rowIndex,
    });
  } else {
    if (AddrFormData[0]['Default_RowIndex'] === "Y") {
      return Object.assign({}, state, {
        AddrFormData,
        AddrFormData1,
        is_Default_present: "N",
      });
    } else {
      return Object.assign({}, state, {
        AddrFormData,
        AddrFormData1,
      });
    }

  }

};

const saveClientForm = (state, payload) => {
  const PersonPk = payload.n_PersonInfoId_PK;
  return Object.assign({}, state, {
    PersonPk,
    PersonInfo: [],
    RoleFormData1: [],
    AddrFormData1: [],
  });
};

const saveRole = (state, payload) => {

  const RoleFormData1 = state.RoleFormData1;
  const RoleFormData = payload;
  if (RoleFormData[0]['s_PersonRoleType'] === 'MORTGAGEE' && RoleFormData[0]['s_isActiveYN'] === 'Y') {
    state.isMortgagee = "Y";
  } else {
    state.isMortgagee = "N";
  }
  const rowIndex = RoleFormData[0]['roleRowIndex'];
  if (String(rowIndex) === '') {
    RoleFormData1.push(RoleFormData[0]);
  } else {
    RoleFormData1[rowIndex] = RoleFormData[0];
  }
  return Object.assign({}, state, {
    RoleFormData1,
  });
};

const setClientPk = (state, payload) => {
  return Object.assign({}, state, {
    ClientPk: payload,
    showMortTab: false,
  });
};


const Client = (state = initialState, {type, payload = null}) => {
  switch (type) {
    case ActionTypes.SEARCH_CLIENT:
      return searchClient(state, payload);
    case ActionTypes.ADD_CLIENT:
      return getClient(state, payload);
    case ActionTypes.SAVE_ADDR:
      return saveAddr(state, payload);
    case ActionTypes.SAVE_CLIENT_FORM:
      return saveClientForm(state, payload);
    case ActionTypes.SAVE_ROLE:
      return saveRole(state, payload);
    case ActionTypes.SET_CLIENTPK:
      return setClientPk(state, payload);
    default:
      return state;
  }
};

export default Client;
