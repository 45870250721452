import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
// @ts-ignore
import {createBrowserHistory} from 'history';
import Auth from '../../../../modules/User/_reducers/Auth';
import persistStore from './persistStore';
import {AppBaseData} from "../../../../navigation/DefaultLayout/_reducers/DefaultLayoutReducer"
import loader from '../../../../navigation/DefaultLayout/_reducers/loader';
import common from '../../../../navigation/DefaultLayout/_reducers/LayoutReducer';
import {ObserveReducer} from "../../../../modules/_CommonModels/ViewModelBase";
import Client from './ClientManagement';
const history = createBrowserHistory();
const RootReducer = combineReducers({
  router: connectRouter(history),
  Auth,
  persistStore,
  Client,
  loader,
  common,
  AppBaseData,
  ObserveReducer
});

export default RootReducer;
export {history};
