import {Observable, useViewModel, ViewModelBase} from "../../../_CommonModels/ViewModelBase";
import {CallServerPromise} from "../../../../utils/app/call_server";
import AppToast from "../../../../utils/misc/app_toast";

class ForgotPasswordViewModel extends ViewModelBase {
  user: Observable;
  loading: boolean = false;
  constructor() {
    super();
    this.user = new Observable(this, 'user', {})
  }

  componentDidMount() {
  }

  async forgotPassword(values: any) {
    const response: any = await CallServerPromise.forgotPassword(values);
    try {
      if (response.success) {
        AppToast.successMsg(response.message);
        this.props.history.push('/reset-password');
      } else {
        AppToast.errorMsg(response.message)
      }
    } catch (e) {
      AppToast.errorMsg(response.message)
    }
    this.updateView()
  }
}

export default useViewModel(new ForgotPasswordViewModel());
