import * as ActionTypes from '../action-types';

export function authLogin(payload: any) {
  return {
    type: ActionTypes.AUTH_LOGIN,
    payload,
  };
}

export function authLogout() {
  return {
    type: ActionTypes.AUTH_LOGOUT,
  };
}

export function authCheck() {
  return {
    type: ActionTypes.AUTH_CHECK,
  };
}


export function loader(status: any) {
  return {
    type: ActionTypes.LOADING,
    status
  };
}

/************* Dashboard ***************/
export function setDashboardData(payload: any) {
  return {
    type: ActionTypes.SET_DASHBOARD_DATA,
    payload
  }
}

/************* Client Management ***************/
export function searchClient(payload: any) {
  return {
    type: ActionTypes.SEARCH_CLIENT,
    payload,
  };
}

export function addClient(payload: any) {
  //console.log(path)
  return {
    type: ActionTypes.ADD_CLIENT,
    payload,
  };
}

export function saveAddress(payload: any) {
  //console.log(payload)
  return {
    type: ActionTypes.SAVE_ADDR,
    payload,
  };
}

export function saveClientForm(payload: any) {
  //console.log(payload)
  return {
    type: ActionTypes.SAVE_CLIENT_FORM,
    payload,
  };
}

export function saveRole(payload: any) {
  //console.log(payload)
  return {
    type: ActionTypes.SAVE_ROLE,
    payload,
  };
}

export function setClientPk(payload: any) {
  //console.log(payload)
  return {
    type: ActionTypes.SET_CLIENTPK,
    payload,
  };
}
