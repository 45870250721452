import * as ActionTypes from '../../../config/redux/store/action-types';
import Http from '../../../utils/app/Http';

const defaultUser = {
  id: null,
  name: null,
  email: null,
};

const initialState = {
  isAuthenticated: false,
  user: defaultUser,
};

const authLogin = (state: any, payload: any) => {

  const {access_token: AccessToken, returnObject: user} = payload;
  sessionStorage.setItem('access_token', AccessToken);
  sessionStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('user_id', user.Admin_ID);
  sessionStorage.setItem('person_id', user.n_PersonInfoId_FK);
  Http.defaults.headers.common.Authorization = `Bearer ${AccessToken}`;
  const stateObj = Object.assign({}, state, {
    isAuthenticated: true,
    user,
  });
  return stateObj;
};

const checkAuth = (state: any) => {
  let userData = sessionStorage.getItem('user') || '{}';
  if (!userData || userData === 'undefined') {
    userData = "{}";
  }
  const stateObj = Object.assign({}, state, {
    isAuthenticated: !!sessionStorage.getItem('access_token'),
    user: JSON.parse(userData),
  });
  if (state.isAuthenticated) {
    Http.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('access_token')}`;
  }
  return stateObj;
};

const logout = (state: any) => {
  sessionStorage.clear();
  return Object.assign({}, state, {
    isAuthenticated: false,
    user: defaultUser,
  });
};

const Auth = (state = initialState, {type = '', payload = null}) => {
  switch (type) {
    case ActionTypes.AUTH_LOGIN:
      return authLogin(state, payload);
    case ActionTypes.AUTH_CHECK:
      return checkAuth(state);
    case ActionTypes.AUTH_LOGOUT:
      return logout(state);
    default:
      return state;
  }
};

export default Auth;
