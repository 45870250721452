import React from 'react';
// @ts-ignore
import {Route, Switch} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
// @ts-ignore
import {createBrowserHistory} from 'history';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import store, {persistor} from '../../config/redux/store';
import * as action from '../../config/redux/store/actions';
import './scss/App.scss';
import ForgotPassword from "../User/ForgotPassword";
import ResetPassword from "../User/ResetPassword";

export const history = createBrowserHistory();

const loading = () =>
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"/>
  </div>;

// Containers
// const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const DefaultLayout = React.lazy(() => import('../../navigation/DefaultLayout'));

// const Login = React.lazy(() => import('./components/Views/Login'));
const Login = React.lazy(() => import('../User/Login'));
const Register = React.lazy(() => import('../User/Register'));
// const Register = React.lazy(() => import('./components/Views/Register'));
const Page404 = React.lazy(() => import('../_CommonComponents/Page404'));
const Page500 = React.lazy(() => import('../_CommonComponents/Page500'));

store.dispatch(action.authCheck());

const App = ()=>{

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <ToastContainer/>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={(props: any) => <Login {...props}/>}/>
              <Route exact path="/register" name="Register Page" render={(props: any) => <Register {...props}/>}/>
              <Route exact path="/404" name="Page 404" render={(props: any) => <Page404 {...props}/>}/>
              <Route exact path="/500" name="Page 500" render={(props: any) => <Page500 {...props}/>}/>
              <Route exact path="/forgot-password" name="Forgot Password" render={(props: any) => <ForgotPassword {...props}/>}/>
              <Route exact path="/reset-password" name="Forgot Password" render={(props: any) => <ResetPassword {...props}/>}/>
              <Route auth="true" path="/" name="Home" render={(props: any) => <DefaultLayout {...props}/>}/>
            </Switch>
          </React.Suspense>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
