import React from "react"
import useResetPasswordView from "./ViewModels/ResetPasswordViewModel"
import {Formik} from "formik";
import * as Yup from "yup";
import {
  Button, Card, CardBody, CardGroup, Col, Container, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon,
  InputGroupText, Row
} from "reactstrap";

const ResetPassword = (props: any) => {
  const view = useResetPasswordView({props})
  const validationSchema = (values: any) => {
    return Yup.object().shape({
      password: Yup.string()
        .min(6, `Password has to be at least ${6} characters!`)
        .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/, 'Password must contain: numbers, uppercase and lowercase letters\n')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([values.password], 'Passwords must match')
        .required('Password confirmation is required'),
    })
  }


  const validate = (getValidationSchema: any) => {
    return (values: any) => {
      const validationSchema = getValidationSchema(values)
      try {
        validationSchema.validateSync(values, {abortEarly: false})
        return {}
      } catch (error) {
        return getErrorsFromValidationError(error)
      }
    }
  }

  const getErrorsFromValidationError = (validationError: any) => {
    const FIRST_ERROR = 0
    return validationError.inner.reduce((errors: any, error: any) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      }
    }, {})
  }
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <h1>Reset Password</h1>
                  <hr/>
                  <Formik
                    initialValues={initialValues}
                    validate={validate(validationSchema)}
                    onSubmit={(values, formikHelpers) => view.resetPassword(values, formikHelpers)}
                  >
                    {
                      ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         isValid,
                         handleReset,
                       }) => (
                        <Form onSubmit={handleSubmit} noValidate name='registerForm'>
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock"/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="password"
                                   name="password"
                                   id="password"
                                   placeholder="Password"
                                   autoComplete="new-password"
                                   valid={!errors.password && touched.password}
                                   invalid={!!errors.password && touched.password}
                                   required
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.password}/>
                            {/*<FormFeedback>Required password containing at least: number, uppercase and lowercase letter, 8 characters</FormFeedback>*/}
                            <FormFeedback>{errors.password}</FormFeedback>
                          </InputGroup>
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock"/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="password"
                                   name="confirmPassword"
                                   id="confirmPassword"
                                   placeholder="Confirm password"
                                   autoComplete="new-password"
                                   valid={!errors.confirmPassword && touched.confirmPassword}
                                   invalid={!!errors.confirmPassword && touched.confirmPassword}
                                   required
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.confirmPassword}/>
                            <FormFeedback>{errors.confirmPassword}</FormFeedback>
                          </InputGroup>
                          <FormGroup>
                            <Button type="submit" color="primary" className="mr-1" block
                                    disabled={isSubmitting || !isValid}>{isSubmitting ? 'Wait...' : 'Submit'}</Button>
                          </FormGroup>
                        </Form>
                      )}
                  </Formik>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default ResetPassword
