import React from "react"
import useForgotPasswordView from "./ViewModels/ForgotPasswordViewModel"
import {Formik} from "formik";
import * as Yup from "yup";
import {
  Button, Card, CardBody, CardGroup, Col, Container, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon,
  InputGroupText, Row
} from "reactstrap";

const ForgotPassword = (props: any) => {
  const view = useForgotPasswordView({props})
  const validationSchema = (values: any) => {
    return Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required!'),
    })
  }


  const validate = (getValidationSchema: any) => {
    return (values: any) => {
      const validationSchema = getValidationSchema(values)
      try {
        validationSchema.validateSync(values, {abortEarly: false})
        return {}
      } catch (error) {
        return getErrorsFromValidationError(error)
      }
    }
  }

  const getErrorsFromValidationError = (validationError: any) => {
    const FIRST_ERROR = 0
    return validationError.inner.reduce((errors: any, error: any) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      }
    }, {})
  }
  const initialValues = {
    email: "",
  };
  const sendRequest = async (values:any, {setSubmitting}:any)=>{
    setSubmitting(true)
    const response = await view.forgotPassword(values)
    console.log(response,'response');
    if(response.success){

    }
  }
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <h1>Forgot Your Password?</h1>
                  <hr/>
                  <Formik
                    initialValues={initialValues}
                    validate={validate(validationSchema)}
                    onSubmit={(values, formikHelpers) =>sendRequest(values, formikHelpers)}
                  >
                    {
                      ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         isValid,
                         handleReset,
                       }) => (
                        <Form onSubmit={handleSubmit} noValidate name='registerForm'>
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-envelope-open"/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="email"
                                   name="email"
                                   id="email"
                                   placeholder="Email"
                                   autoComplete="email"
                                   valid={!errors.email && touched.email}
                                   invalid={!!errors.email && touched.email}
                                   required
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.email}/>
                            <FormFeedback>{errors.email}</FormFeedback>
                          </InputGroup>
                          <FormGroup>
                            <Button type="submit" color="primary" className="mr-1" block
                                    disabled={isSubmitting || !isValid}>{isSubmitting ? 'Wait...' : 'Submit'}</Button>
                          </FormGroup>
                        </Form>
                      )}
                  </Formik>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default ForgotPassword
